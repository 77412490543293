.nav-button {
  position: relative;
  padding: 12px;
  gap: 10px;
  border-radius: 8px;
  background: var(--White, #FFF);
  border: none;
}

.nav-button:hover {
  background: var(--Primary-50, #EEF4FF);
}

.nav-button-icon {
  max-width: 32px;
  max-height: 32px;
  margin: auto;
}

.nav-button__dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--error-500, #F04438);
  position: absolute;
  right: calc(50% - 13px);
  top: calc(50% - 13px);
}

@media screen and (max-width: 500px) {
  .nav-button-icon {
    max-width: 24px;
    max-height: 24px;
  }
}

@media screen and (max-width: 400px) {
  .nav-button-icon {
    max-width: 20px;
    max-height: 20px;
  }
}