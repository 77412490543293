.reaction {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: var(--Gray-700, #344054);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  height: 24px;
  padding: 2px 8px;
  border-radius: 16px;
  background: var(--Gray-50, #F9FAFB);
}

.reaction-small {
  display: flex;
  height: 24px;
  padding: 2px 8px;
  border-radius: 16px;
  background: var(--Gray-50, #F9FAFB);
}

.modal {
  padding: 2px 12px;
  margin: 12px 0px 16px 0px;
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reaction-large {
  height: auto;
  padding: 12px;
  border-radius: 50%;
  transition: background 0.05s ease;
}

.reaction:hover, .reaction-small:hover,
.reaction-summary:hover {
  cursor: pointer;
  background: var(--gray-300, #D0D5DD);
}

.reaction-large:hover {
  cursor: pointer;
  background: var(--gray-100, #F2F4F7);
}

.reaction-summary:first-of-type {
  margin-left: 0;
  z-index: 2;
}

.reaction-summary:not(:first-of-type) {
  margin-left: -6px;
  z-index: 1;
}


.choose, .choose-small {
  color: var(--White, #FFF);
  font-weight: 500;
  background: var(--primary-100-main, #DFEBFF);
  border: 1px solid var(--primary-200-main, #CADCFC);
}

.reaction-summary {
  height: auto;
  padding: 4px;
  border-radius: 50%;
  background: var(--Gray-100, #F2F4F7);
  border: 2px solid #FFFFFF;
  cursor: pointer;
  transition: background 0.05s ease;
}

.choose-large, .choose-summary {
  border-radius: 50%;
}

.choose-summary {
  background: var(--primary-600-main, #367DFF);
}

.icon {
  background-position: center center;
  background-repeat: no-repeat;
}

.icon-small {
  width: 16px;
  height: 16px;
  margin-top: 2px;
  background-size: 16px;
}

.icon-large {
  width: 20px;
  height: 20px;
  background-size: contain;
}

.icon-summary {
  width: 16px;
  height: 16px;
  background-size: contain;
}

.count {
  padding-left: 4px;
  margin-bottom: 0;
  color: var(--gray-700, #344054);
}

.choose-small .count {
  color: var(--primary-600-main, #367DFF);
}


@media screen and (max-width: 1280px) {
  .modal {
    padding: 2px 8px;
  }
}

@media screen and (max-width: 375px) {
  .modal {
    padding: 2px 2px;
  }
  .count {
    padding-left: 2px;
  }
}