.no-posts {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 32px;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}

.no-posts__picture {
  width: 100%;
  height: 200px;
  background: url(../../../assets/images/no-posts.svg) no-repeat center;
  background-size: contain;
}

.no-posts__footer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.no-posts__text {
  width: 100%;
  color: var(--Gray-700, #344054);
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
}

.no-posts__title {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}


@media screen and (max-width: 580px) {
  .no-posts {
    padding: 40px 16px;
    gap: 20px;
  }

  .no-posts__picture {
    height: 148px;
  }

  .no-posts__footer {
    align-items: stretch;
  }
}