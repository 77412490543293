.title {
  margin-bottom: 12px;
  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  text-align: center;
}

.subtitle {
  margin-bottom: 32px;
  color: var(--gray-500, #667085);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.form-registr {
  display: flex;
  flex-direction: column;
}
.bottomWindowBlock {
  margin: 0 0 0 0;
  text-align: center;
  color: var(--gray-500, #667085);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.userAgreementBlock {
  text-align: center;
  color: var(--gray-400, #98A2B3);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 8px 0 8px 0;
}

.socialButtonsBlock {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding-bottom: 20px;
}

.socialButtonsBlock button {
  width: 168px;
}

.usernameBlock{
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.usernameBlock input {
width: 254px;
}

.inputBlock {
  padding-bottom: 4px;
}

@media (min-width: 768px) and (max-width: 1080px) {
  .usernameBlock input {
    width: 310px;
  }

  .socialButtonsBlock button {
    width: 205px;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .title {
    font-size: 24px;
    line-height: 32px;
  }

  .subtitle {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 24px;
  }

  .bottomInputBlock {
    margin-bottom: 24px;
  }
  .usernameBlock{
    flex-direction: column;
    gap:0;
  }

  .usernameBlock input {
    width: 100%;
  }
}