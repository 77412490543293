.line {
  padding: 0;
  margin: 0;
  margin-bottom: 8px;

  color: var(--Gray-700, #344054);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  overflow-wrap: break-word;
}

.line-trends {
  font-weight: 500;
}

.link {
  color: var(--Primary-700, #1E5BCB);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
}

.link:hover {
  cursor: pointer;
}