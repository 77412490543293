.tooltip {
  font-family: Inter;
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  z-index: 100;
}

.tooltip__input {
  position: absolute;
  transform: translateX(-50%);
  right: 14px;
  top: 40px;
}

.tooltiptext {
  visibility: hidden;
  width: 262px;
  height: 52px;
  text-align: center;
  color: var(--white, #FFFFFF);
  background-color: var(--gray-700, #344054);
  padding: 8px 12px 8px 12px;
  border-radius: 8px;
  position: absolute;
  align-items: center;
  z-index: 100;
}

.tooltiptext-big{
  width: 320px;
  height: 100px;
}

.tooltiptext-xs{
  width: 136px;
  height: 34px;
}

.tooltiptext-center-up {
  bottom: 100%;
  left: 50%;
  margin-left: -140px;
  margin-bottom: 3px;
}

.visible{
  visibility: visible
}

.hidden{
  visibility: hidden;
}

.tooltiptext-center-down {
  top: 100%;
  left: 50%;
  margin-left: -140px;
  margin-top: 3px;
}

.tooltiptext-arrow-left {
  top: -15px;
  right: 150%;
}

.tooltiptext-arrow-left::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #344054;
}

.tooltiptext-arrow-right {
  top: -15px;
  left: 150%;
}

.tooltiptext-arrow-right::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #344054 transparent transparent;
}

.tooltiptext-arrow-up {
  top: 100%;
  left: 50%;
  margin-left: -10px;
  margin-top: 8px;
}

.tooltiptext-arrow-up::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: 5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #344054 transparent;
}

.tooltiptext-arrow-down {
  bottom: 100%;
  margin-left: -100px;
  margin-bottom: 8px;
}

.tooltiptext-arrow-down::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: 5px;
  border-width: 5px;
  border-style: solid;
  border-color:#344054 transparent transparent transparent;
}

.tooltiptext-arrow-down-left {
  bottom: 100%;
  right: -200%;
  margin-bottom: 8px;
}

.tooltiptext-arrow-down-left::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 80%;
  margin-left: 5px;
  border-width: 5px;
  border-style: solid;
  border-color:#344054 transparent transparent transparent;
}

.tooltiptext-arrow-down-right {
  bottom: 100%;
  right: -200%;
  margin-bottom: 8px;
}

.tooltiptext-arrow-down-right::after {
  content: " ";
  position: absolute;
  top: 100%;
  right: 80%;
  margin-left: 5px;
  border-width: 5px;
  border-style: solid;
  border-color:#344054 transparent transparent transparent;
}



@media (min-width: 768px) and (max-width: 1080px) {
.tooltiptext-center-up {
    margin-left: -80px;
    transform: translateX(-50%);
  }
}

@media (min-width: 375px) and (max-width: 767px) {
.tooltiptext-center-up {
    margin-left: -90px;
    transform: translateX(-50%);
  }
}

@media (max-width: 374px), (max-height: 374px) {
.tooltiptext-center-up {
    display: none;
  }
}