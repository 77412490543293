.toggle-container{
display: flex;
justify-content: space-between;
}

.toggle-title{
  color: var(--gray-700, #344054);
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.disabled {
  color: var(--gray-400, #98A2B3);
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--gray-100, #F2F4F7);
  transition: .4s;
  border-radius: 12px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
  box-shadow: 0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A;
}

input:checked + .toggle-slider {
  background-color: var(--primary-60, #367DFF);
}

input:focus + .toggle-slider {
  box-shadow: 0 0 1px var(--gray-100, #F2F4F7);
}

input:checked + .toggle-slider:before {
  transform: translateX(20px);
}

input:checked + .toggle-slider_disabled {
  background-color: var(--gray-100, #F2F4F7);
}

.toggle-supportingText{
  margin-top: -10px;
  width: 524px;
  display: flex;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--gray-400, #98A2B3);
}

@media (min-width: 768px) and (max-width: 1080px) {
  .toggle-supportingText{
    width: 530px;
    text-align: justify;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .toggle-supportingText{
    width: 260px;
    text-align: justify;
  }

  .toggle-title{
    width: 260px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}