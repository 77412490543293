.more {
  width: 155px;
  height: 140px;
  overflow: hidden;
  position: relative;
}

.more__picture {
  min-width: 155px;
  height: 140px;
}

.foreground {
  position: absolute;
  display: flex;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(16, 24, 40, 0.80) 0%, rgba(16, 24, 40, 0.80) 100%);
  overflow: hidden;
  border-radius: 8px;

  color: var(--primary-600-main, #367DFF);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.plus {
  display: flex;
  margin: auto;
  width: 40px;
  height: 40px;
  max-width: 60%;
  max-height: 60%;
  padding: 8px 0;
  justify-content: center;
  align-items: center;

  border-radius: 200px;
  border: 2px solid var(--White, #FFF);
  background: var(--Gray-50, #F9FAFB);
}