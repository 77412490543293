.fragment {
  position: absolute;
  top: 0;
  left: 101svw;
  width: 100svw;
  height: 100svh;
  background-color: white;
  z-index: 3999;
  transition: all .3s;
}

.fragment_show {
  left: 0;
}

.fragment__content {
  padding: 16px;
}

.fragment__send {
  margin-left: auto;
}