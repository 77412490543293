.galery {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.prev-button {
  left: 0;
  padding: 16px;
  display: flex;
  align-items: center;
  height: 100svh;
  width: 92px;
}

.next-button {
  right: 0;
  padding: 16px;
  display: flex;
  justify-content: right;
  align-items: center;
  height: 100svh;
  width: 92px;
}

.button {
  padding: 8px;
  z-index: 4020;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  background: var(--White, #FFF);
  backdrop-filter: blur(4px);
  border: none;
}

.picture {
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-height: 100svh; */
  max-width: 100%;
}

.media-item {
  margin: auto;
  z-index: 4020;
  display: block;
  max-width: 100%;
  max-height: 100%;
  /* z-index: 4899; */
  /* background-size: contain; */
  border-radius: 0;
  /* background-color: transparent; */
}
