.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  font-weight: 600;
  height: min-content;
}

.button:focus {
  outline: none;
}

.button_size-sm {
  padding: 8px 14px;
  font-size: 12px;
}

.button_size-social-sm {
  padding: 8px 40px;
  font-size: 14px;
  line-height: 20px;
}

.button_size-sm-icon {
  padding: 8px;
}

.button_size-md {
  padding: 10px 16px;
  font-size: 14px;
}

.button_size-md-icon {
  padding: 10px;
}

.button_size-lg {
  padding: 10px 18px;
  font-size: 16px;
}

.button_size-lg-icon {
  padding: 12px;
}

.button_size-xl {
  padding: 12px 20px;
  font-size: 16px;
}

.button_size-xl-icon {
  padding: 14px;
}

.button_size-2xl {
  padding: 16px 28px;
  font-size: 18px;
}

.button_size-3xl {
  padding: 6px 8px;
  font-size: 12px;
}

.button_size-2xl-icon {
  padding: 16px;
}

/* primary */

.button_primary {
  color: var(--white, #fff);
  border: 1px solid var(--primary-600-main, #367dff);
  background: var(--primary-600-main, #367dff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.button_primary:hover {
  border: 1px solid var(--primary-700, #1e5bcb);
  background: var(--primary-700, #1e5bcb);
}

.button_primary:focus {
  box-shadow: 0px 0px 0px 4px #dfebff, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.button_primary:disabled {
  border: 1px solid var(--primary-200, #cadcfc);
  background: var(--primary-200, #cadcfc);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

/* secondary-color */

.button_secondary-color {
  color: var(--primary-700, #1e5bcb);
  border: 1px solid var(--primary-50, #eef4ff);
  background: var(--primary-50, #eef4ff);
}

.button_secondary-color:hover {
  border: 1px solid var(--primary-100, #dfebff);
  background: var(--primary-100, #dfebff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.button_secondary-color:focus {
  box-shadow: 0px 0px 0px 4px #dfebff, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.button_secondary-color:disabled {
  color: var(--primary-300, #adcaff);
  border: 1px solid var(--primary-25, #f8faff);
  background: var(--primary-25, #f8faff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

/* primary-gray */

.button_primary-gray {
  color: var(--gray-700, #344054);
  border: 1px solid var(--gray-100, #F2F4F7);
  background: var(--White, #fff);
  box-shadow: none;
}

.button_primary-gray:hover {
  background: var(--gray-100, #F2F4F7);
}

.button_primary-gray:focus {
  box-shadow: none
}

.button_primary-gray:disabled {
  color: var(--gray-300, #d0d5dd);
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--White, #fff);
}

/* secondary-gray */

.button_secondary-gray {
  color: var(--gray-700, #344054);
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.button_secondary-gray:hover {
  background: var(--gray-50, #f9fafb);
}

.button_secondary-gray:focus {
  box-shadow: 0px 0px 0px 4px #f2f4f7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.button_secondary-gray:disabled {
  color: var(--gray-300, #d0d5dd);
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--White, #fff);
}

/* tertiary-color */

.button_tertiary-color {
  color: var(--primary-700, #1e5bcb);
  border: 1px solid transparent;
  background: none;
}

.button_tertiary-color:hover {
  background: var(--primary-50, #eef4ff);
}

.button_tertiary-color:focus {
  background: var(--primary-50, #eef4ff);
  box-shadow: 0px 0px 0px 4px #dfebff, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.button_tertiary-color:disabled {
  color: var(--primary-200, #cadcfc);
  background: none;
}

/* tertiary-gray */

.button_tertiary-gray {
  color: var(--gray-500, #667085);
  border: 1px solid transparent;
  background: none;
}

.button_tertiary-gray:hover {
  color: var(--gray-600, #475467);
}

.button_tertiary-gray:focus {
  box-shadow: 0px 0px 0px 4px #f2f4f7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.button_tertiary-gray:disabled {
  color: var(--gray-300, #d0d5dd);
  background: none;
}

/* quaternary-color */

.button_quaternary-color {
  color: var(--primary-700, #1e5bcb);
  border: 1px solid var(--primary-50, #eef4ff);
  background: var(--primary-50, #eef4ff);
}

.button_quaternary-color:hover {
  border: 1px solid var(--primary-50, #eef4ff);
}

.button_quaternary-color:disabled {
  color: var(--gray-300, #d0d5dd);
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--White, #fff);
}

/* quinary-color*/

.button_quinary-color {
  color: var(--primary-700, #1e5bcb);
  border: none;
  background: var(--White, #fff);
}

.button_quinary-color:hover {
  border: none;
  background: var(--White, #fff);
  box-shadow: none;
}

.button_quinary-color:focus {
  box-shadow: none;
}

.button_quinary-color:disabled {
  color: var(--primary-300, #adcaff);
  border: 1px solid var(--primary-25, #f8faff);
  background: var(--primary-25, #f8faff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

/* link-color */

.button_link-color {
  padding: 0;
  border: none;
  color: var(--primary-700, #1e5bcb);
  background: none;
}

.button_link-color:hover {
  color: var(--primary-800, #0d3c92);
}

.button_link-color:focus {
  color: var(--primary-700, #1e5bcb);
}

.button_link-color:disabled {
  color: var(--primary-200, #cadcfc);
}


/* link-gray */

.button_link-gray {
  padding: 0;
  border: none;
  color: var(--gray-400, #98A2B3);
  background: none;
}

.button_link-gray:hover {
  color: var(--gray-600, #475467);
}

.button_link-gray:focus {
  color: var(--gray-500, #667085);
}

.button_link-gray:disabled {
  color: var(--gray-300, #d0d5dd);
}

/* primary-destructive */

.button_primary-destructive {
  color: var(--white, #fff);
  border: 1px solid var(--error-600, #d92d20);
  background: var(--error-600, #d92d20);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.button_primary-destructive:hover {
  border: 1px solid var(--error-700, #b42318);
  background: var(--error-700, #b42318);
}

.button_primary-destructive:focus {
  box-shadow: 0px 0px 0px 4px #fee4e2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.button_primary-destructive:disabled {
  border: 1px solid var(--error-200, #fecdca);
  background: var(--error-200, #fecdca);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

/* secondary-color-destructive */

.button_secondary-color-destructive {
  color: var(--error-700, #b42318);
  border: 1px solid var(--error-50, #fef3f2);
  background: var(--error-50, #fef3f2);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.button_secondary-color-destructive:hover {
  border: 1px solid var(--error-100, #fee4e2);
  background: var(--error-100, #fee4e2);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.button_secondary-color-destructive:focus {
  box-shadow: 0px 0px 0px 4px #fee4e2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.button_secondary-color-destructive:disabled {
  color: var(--error-300, #fda29b);
  border: 1px solid var(--error-25, #fffbfa);
  background: var(--error-25, #fffbfa);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

/* secondary-gray-destructive */

.button_secondary-gray-destructive {
  color: var(--error-700, #b42318);
  border: 1px solid var(--error-300, #fda29b);
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.button_secondary-gray-destructive:hover {
  color: var(--error-800, #912018);
  border: 1px solid var(--error-300, #fda29b);
  background: var(--error-50, #fef3f2);
}

.button_secondary-gray-destructive:focus {
  box-shadow: 0px 0px 0px 4px #fee4e2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.button_secondary-gray-destructive:disabled {
  color: var(--error-300, #fda29b);
  border: 1px solid var(--error-200, #fecdca);
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

/* tertiary-color-destructive */

.button_tertiary-color-destructive {
  color: var(--error-700, #b42318);
  border: 1px solid transparent;
  background: none;
}

.button_tertiary-color-destructive:hover {
  background: var(--error-50, #fef3f2);
}

.button_tertiary-color-destructive:focus {
  background: var(--error-50, #fef3f2);
  box-shadow: 0px 0px 0px 4px #fee4e2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.button_tertiary-color-destructive:disabled {
  color: var(--error-300, #fda29b);
  background: none;
}

/* tertiary-gray-destructive */

.button_tertiary-gray-destructive {
  color: var(--error-700, #b42318);
  border: 1px solid transparent;
  background: none;
}

.button_tertiary-gray-destructive:hover {
  color: var(--error-800, #912018);
  background: var(--error-50, #fef3f2);
}

.button_tertiary-gray-destructive:focus {
  background: var(--error-50, #fef3f2);
  box-shadow: 0px 0px 0px 4px #fee4e2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.button_tertiary-gray-destructive:disabled {
  color: var(--error-300, #fda29b);
  background: none;
}

/* link-color-destructive */

.button_link-color-destructive {
  padding: 0;
  border: none;
  color: var(--error-700, #b42318);
  background: none;
}

.button_link-color-destructive:hover {
  color: var(--error-800, #912018);
}

.button_link-color-destructive:focus {
  color: var(--error-700, #b42318);
}

.button_link-color-destructive:disabled {
  color: var(--error-300, #fda29b);
}

/* link-gray-destructive */

.button_link-gray-destructive {
  padding: 0;
  border: none;
  color: var(--error-700, #b42318);
  background: none;
}

.button_link-gray-destructive:hover {
  color: var(--error-800, #912018);
}

.button_link-gray-destructive:focus {
  color: var(--error-700, #b42318);
}

.button_link-gray-destructive:disabled {
  color: var(--error-300, #fda29b);
}


.button_icon-sm {}

.button_icon-ss {}

.button_icon-md {}

.button_icon-lg {}

.button_icon-xl {}

.button_icon-2xl {}

.button__max-width {
  width: 100%;
}