.outbox {
  display: flex;
  flex-direction: column;
  min-width: 100svw;
  max-height: 100svh;
}

.container {
  width: 1280px;
  margin: 0 auto;
  display: flex;
}

@media screen and (max-width: 1280px) {
  .container {
    margin: 0;
    display: flex;
    gap: 0;
  }
}