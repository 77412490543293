.link-data {
  position: relative;
  display: flex;
  border-radius: 8px;
  border: 1px solid var(--Gray-200, #EAECF0);
  background: var(--Gray-25, #FCFCFD);

  cursor: pointer;
  overflow: hidden;
}

.link-data__picture {
  display: flex;
  min-width: 112px;
  min-height: 112px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--gray-200, #EAECF0);
  background-image: url(../../../assets/icons/no-image.svg);
}

.link-data__right {
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.link-data__link {
  margin: 0;
  padding: 0;

  color: var(--Gray-500, #667085);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link-data__title {
  margin: 0;
  padding: 0;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;

  color: var(--Gray-700, #344054);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.link-data__text {
  margin: 0;
  padding: 0;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;

  color: var(--Gray-500, #667085);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.link-data__close {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--Gray-400, #98A2B3);
  background-image: url(../../../assets/icons/close-link.svg);
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.link-data__close:hover {
  background-color: var(--Gray-300, #D0D5DD);
}