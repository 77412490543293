.geo-button {
  display: flex;
  gap: 8px;
  line-height: 90%;
  align-items: center;
  font-size: 14px;
}

.geo-button__icon {}

.geo-button__text {
  width: 155px;
}

@media screen and (max-width: 767px) {
  .geo-button__text {
    display: none;
  }
}