.item {
  padding: 12px 16px;
}

.item:hover {
  cursor: pointer;
  background-color: var(--White, #FFFFFF);
}

.item:hover .item__title {
  text-decoration: underline;
}

.item__title {
  overflow: hidden;
  color: var(--Gray-700, #344054);
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.item__bottom {
  display: flex;
  gap: 6px;
  align-items: center;
}

.item__fullname {
  max-width: 120px;
  margin: 0;
  overflow: hidden;
  color: var(--Gray-700, #344054);
  text-overflow: ellipsis;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.item__time {
  margin: 0;
  overflow: hidden;
  color: var(--Gray-400, #98A2B3);
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.item__views {
  display: flex;
  margin: 0 0 0 auto;
  gap: 6px;
  overflow: hidden;
  color: var(--Gray-400, #98A2B3);
  text-align: right;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}