.window {
  position: absolute;
  display: flex;
  gap: 0;
  top: 0;
  left: 0;
  position: fixed;
  width: 100svw;
  height: 100svh;
  z-index: 3999;
}

.window__back {
  z-index: 4010;
  width: 100svw;
  height: 100svh;
  position: absolute;
  background: rgba(16, 24, 40, 0.95);
}

.button {
  position: fixed;
  z-index: 5042;
  padding: 0;
  left: 16px;
  top: 16px;
  width: 32px;
  height: 32px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 4020;
}

.media {
  max-height: auto;
}

.post-content {
  position: relative;
  overflow-x: auto;
  display: flex;
  z-index: 4020;
  width: 392px;
  height: 100svh;
  padding: 20px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  background: var(--White, #FFF);
}

.carousel {
  display: none;
}

@media screen and (max-width: 1280px) {
  .window__back {
    display: none;
  }

  .window {
    flex-direction: column;
    background-color: var(--White, #FFFFFF);
  }

  .post-content {
    min-height: 24px;
    height: auto;
    width: auto;
    padding-bottom: 0;
  }

  .media {
    display: none !important;
  }

  .carousel {
    display: block;
    height: 100%;
    max-height: 100svw;;
  }

  .carousel > div:first-child > div {
    border-radius: 0;
  }
}

@media screen and (max-width: 580px) {
  .post-content {
    padding: 8px 0 0 0;
  }
}