.toastContainer {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 85px;
  left: 50%;
  transform: translateX(-50%);
  gap:20px;
  z-index: 9001;
}

.toast {
  width: max-content;
  height: 68px;
  border-radius: 8px;
  padding: 12px, 8px, 12px, 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.toast-Error {
  border: 1px solid var(--error-600, #D92D20);
  background: var(--error-50, #FEF3F2);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.toast-Warning {
  border: 1px solid var(--warning-50, #FFFAEB);
  background: var(--warning-600, #DC6803);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.toast-Info {
  border: 1px solid var(--primary-60, #367DFF);
  background: var(--primary-50, #eef4ff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.toast-Success {
  border: 1px solid var(--success-600, #039855);
  background: var(--success-50, #ECFDF3);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.toast-Copy {
  border: 1px solid var(--primary-600-main, #367DFF);
  background: var(--Primary-50, #EEF4FF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.toast-notify {
  border: 1px solid var(--primary-600-main, #367DFF);
  background: var(--Primary-50, #EEF4FF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);  
}

.toast-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding: 0 14px 0 16px;
  min-width: 528px;
}

.button_toast-close {
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  font-size: 1.8rem;
  margin-left: auto;
}


.toast-action {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.actionButton {
  background: #007bff;
  border: none;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.toast-message{
  flex-grow: 1;
  flex-shrink: 0;
  padding-left: 12px;
  max-width: fit-content;
}

@media screen and (max-width: 767px) {
  .toast {
    min-width: 365px;
    max-width: 95svw;
    width: 80svw;
    height: 56px;
  }

  .toast-content {
    gap: 8px;
    padding: 0 6px 0 12px;
    min-width: 0;
  }

  .toast-message{
    padding-left: 8px;
    width: 50%;
  }
}