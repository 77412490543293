.main-menu_background {
  display: flex;
  flex-direction: column;
  min-height: 100svh;
  min-width: 240px;
  border-right: 1px solid var(--gray-200, #EAECF0);
  margin-right: -1px;
}

.main-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  min-height: 100svh;
}

.logo {
  margin: 20px 0;
}

.logo img{
  width: 128px;
  height: 52px;
}
.menu {
  width: 208px;
  margin: 0;
  border: 0;
  box-shadow: none;
}

.menu__item {
  font-size: 16px;
  line-height: 24px;
  padding: 10px 14px;
}

.menu__item:hover {
  border-radius: 8px;
}

.button {
  margin-top: 4px;
  margin-bottom: auto;
}

.avatar-group {
  display: flex;
  width: 100%;
  gap: 4px;
  align-items: center;
  margin-top: auto;
  margin-bottom: 20px;
}

.left {
  width: 184px;
}

.logomark, .tab-button, .tab-avatar, .nav-menu {
  display: none;
}

@media screen and (max-width: 1280px) {
  .full-logo, .button, .avatar-group {
    display: none;
  }

  .logomark, .tab-button, .tab-avatar {
    display: flex;
  }

  .logo {
    margin: 20px 4px;
  }

  .logo img{
    width: 52px;
    height: 52px;
  }

  .tab-menu_background {
    display: flex;
    flex-direction: column;
    min-height: 100svh;
    min-width: 60px;
  }

  .main-menu {
    max-width: 60px;
    align-items: center;
  }

  .main-menu_background {
    min-width: 60px;
    border-right: 1px solid var(--gray-200, #EAECF0);
    margin-right: -1px;
  }

  .menu {
    display: none;
  }

  .tab-button {
    max-width: 48px;
    margin: 0 6px;
  }

  .tab-avatar {
    margin: 20px auto;
  }

  .nav-menu {
    display: flex;
    margin: 0 auto 4px;
  }
}

@media screen and (max-width: 580px) {
  .main-menu, .main-menu_background {
    display: none;
  }
}