.reactions-container {
  display: flex;
  position: absolute;
  justify-content: center;
  background-color: white;
  border-radius: 8px;
  align-items: center;
  box-shadow: 0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A;
}

.new-posts {
  left: 130px;
  top: -5px;
}

.messages {
  top: -35px;
  right: 35px;
}

.reactions {
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 12px;
}


@media screen and (max-width: 680px) {
  .new-posts {
    left: 80px;
    top: 45px;
    z-index: 101;
  }

}


@media screen and (max-width: 580px) {
  .reactions {
    gap: 2px;
    padding: 6px;
  }

  .new-posts {
    left: 80px;
    top: -40px;
  }

  .messages {
    top: -45px;
    right: 15px;
  }

  .messages .reactions {
    gap: 4px;
    padding: 12px;
  }
}


@media screen and (max-width: 375px) {
  .reactions {
    gap: 2px;
    padding: 6px;
  }

  .new-posts {
    left: 80px;
    top: -40px;
  }

}