.placesContainer {
  position: relative;
  z-index: 10;
  width: 100%;
}

.placesInnerContainer {
  position: absolute;
  padding: 20px;
  width: calc(100% - 40px);
  max-width: calc(100% - 40px);
}

@media screen and (max-width: 767px) {
  .placesInnerContainer {
    padding: 12px 12.5px;
    width: 100%;
    min-width: 100%;
  }
  
  .placesContainer {
    width: 100%;
    max-width: 100%;
  }
}

.mapContainer {
  width: 100%;
  height: 100%;
  position: relative;
}