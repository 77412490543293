.tabs {
  display: flex;
  flex-wrap: no-wrap;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
  padding: 8px 16px 0;
}

.item {
  width: 100%;
  padding-bottom: 16px;
  text-align: center;
  list-style-type: none;
  color: var(--gray-500, #667085);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
}

.item:hover {
  color: var(--primary-500, #5993FE);
  border-bottom: 2px solid var(--primary-500, #5993FE);
}

.item_active {
  color: var(--primary-700, #1E5BCB);
  border-bottom: 2px solid var(--primary-700, #1E5BCB);
  cursor: default;
}

.item_active:hover {
  color: var(--primary-700, #1E5BCB);
  border-bottom: 2px solid var(--primary-700, #1E5BCB);
}

.item_disabled {
  cursor: default;
  color: var(--gray-300, #D0D5DD);
  border-bottom: none;
}

.item_disabled:hover {
  cursor: default;
  color: var(--gray-300, #D0D5DD);
  border-bottom: none;
}

.tabs-sm {}

.tabs-sm .item {
  font-size: 14px;
  line-height: 20px;
}