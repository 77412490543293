.title {
  margin-bottom: 0;
  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  text-align: center;
}

.map {
  max-width: calc(100svw - 128px);
  width: 944px;
  height: 580px;
  max-height: 660px;
  border-radius: 8px;
  overflow: hidden;
  margin: 32px 0;
}

/* @media screen and (max-width: 944px) {
  .map {
    width: 640px;
    height: 580px;
    border-radius: 8px;
    overflow: hidden;
    margin: 32px 0;
  }
} */

@media screen and (max-width: 768px) {
  .title {
    font-size: 24px;
    line-height: 32px;
  }

  .map {
    width: 343px;
    max-width: calc(100svw - 32px);
    height: 420px;
    border-radius: 8px;
    overflow: hidden;
    margin: 32px 0;
  }
}