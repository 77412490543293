.modal_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #344054CC;
  z-index: 9000;
}

.modal_container {
  position: fixed;
  width: 592px;
  left: 50%;
  top: 15%;
  transform: translateX(-50%);
  border-radius: 12px;
  background-color: white;
  font-family: Inter;
  font-style: normal;
  box-shadow: 0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814;
  z-index: 9001;
}

.modal_content {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
}

.modal_header {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  color: var(--gray-700, #344054);
}

.modal_title{
  margin-top: 0px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: var(--gray-700, #344054);
}

.modal_supporting-text{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-items: start;
  color: var(--gray-500, #667085);
}


.modal_close_button {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  color: var(--gray-900, #101828);
  cursor: pointer;
  font-size: 1.5rem;
}

.modal_footer {
  display: flex;
  gap: 12px;
  margin-top: auto;
  width: 100%;
}

.modal_footer button {
  flex-grow: 1;
}

@media (min-width: 375px) and (max-width: 767px) {
  .modal_container {
    width: 328px;
  }

  .modal_content {
    padding: 20px 16px 16px 16px;
  }

  .modal_footer {
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal_title{
    font-size: 20px;
    line-height: 30px;
  }

  .modal_container {
    top: auto;
    bottom: 1%;
  }
}