.mini-media-content {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.mini-media-content__item {
  width: 44px;
  min-width: 44px;
  height: 44px;
  min-height: 44px;
  font-size: 12px !important;
}