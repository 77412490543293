.header {
  display: flex;
  position: fixed;
  min-width: 697px;
  min-height: 86px;
  align-items: center;
  padding: 20px 32px;
  gap: 16px;
  margin-right: auto;
  border: 1px solid var(--gray-200, #EAECF0);
  border-top: none;
  border-bottom: none;
  z-index: 1999;
  background: rgba(255, 255, 255, 0.70);
  backdrop-filter: blur(10px);
}

.header_back {
  height: 86px;
}


.text {
  color: var(--gray-700, #344054);
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.city {
  margin-left: auto;
}

.avatar, .logomark, .text-mobile, .login-button {
  display: none;
}

.subtitle {
  color: var(--Gray-400, #98A2B3);

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.header-settigs{
  min-width: 383px;
}

@media screen and (max-width: 1280px) {
  .avatar {
    margin: auto 0;
    display: flex;
  }

  .avatar_no-main {
    margin-left: auto;
  }

  .header {
    min-width: auto;
    width: calc(100svw - 60px);
  }

  .login-button {
    display: flex;
  }
}

@media screen and (max-width: 580px) {
  .logomark {
    display: flex;
  }

  .header {
    min-width: 100%;
    padding: 16px;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px; /* 150% */
  }

  .header_back {
    height: 84px;
  }

  .text {
    display: none;
  }

  .text-mobile {
    display: flex;
    color: var(--gray-700, #344054);
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }
}