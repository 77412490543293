.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon_xxxs svg {
  width: 12px;
  height: 12px;
}

.icon_xxs svg {
  width: 16px;
  height: 16px;
}

.icon_xxs-plus svg {
  width: 20px;
  height: 20px;
}

.icon_xs svg {
  width: 24px;
  height: 24px;
}

.icon_xs-plus svg {
  width: 28px;
  height: 28px;
}

.icon_sm svg {
  width: 32px;
  height: 32px;
}

.icon_md svg {
  width: 40px;
  height: 40px;
}

.icon_lg svg {
  width: 48px;
  height: 48px;
}

.icon_xl svg {
  width: 56px;
  height: 56px;
}

.circle, .circle-shadow{
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.square, .square-shadow{
  border-radius: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle_xxxs, .square_xxxs {
  padding: 6px;
}

.circle_xxs, .square_xxs {
  padding: 8px;
}

.circle_xxs-plus, .square_xxs-plus {
  padding: 10px;
}

.circle_xs, .square_xs{
  padding: 12px;
}

.circle_xs-plus, .square_xs-plus{
  padding: 14px;
}

.circle-shadow_xxxs {
  padding: 6px;
  border: 2px solid;
}

.circle-shadow_xxs {
  padding: 8px;
  border: 4px solid;
}

.circle-shadow_xxs-plus {
  padding: 10px;
  border: 6px solid;
}

.circle-shadow_xs{
  padding: 8px;
  border: 8px solid;
}

.circle-shadow_xs-plus{
  padding: 10px;
  border: 10px solid;
}

.square-shadow_xxxs {
  padding: 6px;
  border: 1px solid;
  box-shadow: 0px 1px 2px 0px #1018280D;
}

.square-shadow_xxs {
  padding: 8px;
  border: 1px solid;
  box-shadow: 0px 1px 2px 0px #1018280D;
}

.square-shadow_xxs-plus {
  padding: 10px;
  border: 1px solid;
  box-shadow: 0px 1px 2px 0px #1018280D;
}

.square-shadow_xs{
  padding: 12px;
  border: 1px solid;
  box-shadow: 0px 1px 2px 0px #1018280D;
}

.square-shadow_xs-plus{
  padding: 14px;
  border: 1px solid;
  box-shadow: 0px 1px 2px 0px #1018280D;
}