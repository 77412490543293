.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: auto 0;
}

.dot-gray {
  background-color: var(--gray-700, #344054);
}

.dot-primary {
  background-color: var(--primary-700, #1E5BCB);
}

.dot-error {
  background-color: var(--error-500, #F04438);
}

.dot-warning {
  background-color: var(--warning-500, #F79009);
}

.dot-success {
  background-color: var(--success-500, #12B76A);
}

.dot-blue-gray {
  background-color: var(--primary-600, #367DFF);
}

.dot-indigo {
  background-color: var(--indigo-500, #6172F3);
}

.dot-purple {
  background-color: var(--purple-500, #7A5AF8);
}

.dot-pink {
  background-color: var(--pink-500, #EE46BC);
}

.dot-rose {
  background-color: var(--rose-500, #F63D68);
}

.dot-orange {
  background-color: var(--orange-500, #FB6514);
}
