.follow-component {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 32px;
  cursor: pointer;
}

.follow-component:hover {
  background-color: var(--Gray-100, #F2F4F7);
}

.follow-component__avatar {
  min-width: 48px;
}

.follow-component__main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.follow-component__fullname {
  margin: 0;
  padding: 0;

  color: var(--Gray-700, #344054);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.follow-component__city {
  margin: 0;
  padding: 0;

  color: var(--Gray-400, #98A2B3);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.follow-button {
  text-wrap: nowrap;
}

@media screen and (max-width: 580px) {
  .follow-component {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 16px;
  }
}