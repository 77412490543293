.group {
  display: flex;
  gap: 12px
}

.right {
  display: flex;
  flex-direction: column;
}

.full-name {
  color: var(--gray-700, #344054);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.username {
  color: var(--gray-400, #98A2B3);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}