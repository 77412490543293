.crop-city-cover-dialog {
  display: flex;
  flex-direction: column;
}

.crop-city-cover-dialog__header {
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
}

.crop-city-cover-dialog__icon {
  width: 48px;
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  padding: 12px;
  margin: 0;
  border-radius: 10px;
  border: 1px solid var(--Gray-200, #EAECF0);
  background: var(--White, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.crop-city-cover-dialog__header-right {
  display: flex;
  flex-direction: column;
}

.crop-city-cover-dialog__title {
  margin: 0;
  padding: 0;
  color: var(--Gray-700, #344054);
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.crop-city-cover-dialog__text {
  margin: 0;
  padding: 0;
  color: var(--Gray-700, #344054);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.crop-city-cover-dialog__image-container {
  max-width: 100%;
  display: flex;
  margin-bottom: 32px;
}

.crop-city-cover-dialog__image-crop {
  margin: 0 auto;
}

.crop-city-cover-dialog__image-crop-img {
  max-height: 50svh !important;
  margin: 0 auto !important;
}

.crop-city-cover-dialog__hr {
  width: calc(100% + 48px);
  height: 1px;
  margin-left: -24px;
  margin-bottom: 24px;
  background: var(--Gray-200, #E4E7EC);
}

.crop-city-cover-dialog__buttom {
  margin-left: auto;
}

.crop-city-cover-dialog__button {
  min-width: 217px;
  min-height: 46px;
}
