.inputBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 20px;
}

.phoneTitle {
  padding: 0;
  margin: 0;
  color: var(--gray-700, #344054);
  font-family: Inter;
  text-shadow: 0px 0px 0px #DFEBFF, 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.input {
  width: 100%;
  padding: 10px 14px;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #D0D5DD);
  background: var(--white, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.input::placeholder {
  color: var(--gray-400, #98A2B3);
}

.input_phone {
  padding-left: 70px;
}

.input_password {
  position: relative;
}

.countryCode {
  position: absolute;
  top: 26px;
  z-index: 10;
  padding: 10px 14px;
  border: 1px solid transparent;
}

.input__dropdown {
  top: 70px;
  left: -8px;
  width: 100%;
  position: absolute;
  height: 0;
  transition: all .1s;
}

.input__dropdown_show {
  height: 320px;
}

.button-pass-eye{
  position: absolute;
  right: 14px;
  top: 38px;
}


.button-pass-eye button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  width: auto;
}

.button-setting-confirm{
  position: absolute;
  right: 16px;
  top: 35px;
}

.button-setting-confirm button {
  border: none;
  background: none;
  padding: 0 14px 0 0;
  cursor: pointer;
  width: auto;
  color: var(--primary-700, #1E5BCB);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.inputBlock_supporting-text {
  color: var(--gray-400, #98A2B3);
}

.input:focus {
  outline: none;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 4px #DFEBFF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid var(--primary-300, #ADCAFF);
}

.input:disabled {
  color: var(--gray-400, #98A2B3);
  border: 1px solid var(--gray-300, #D0D5DD);
  background: var(--gray-50, #F9FAFB);
}

.input_error:focus {
  outline: none;
  border: 1px solid var(--error-300, #FDA29B);
  box-shadow: 0px 0px 0px 4px #fee4e2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.input_error {
  outline: none;
  border: 1px solid var(--error-300, #FDA29B);
  box-shadow: 0px 0px 0px 4px #fee4e2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.input_error-text {
  color: var(--error-500, #F04438);
  font-size: 14px;
}

.input_search {
  padding-left: 40px;
  background-image: url('../../../assets/icons/search-icon.svg');
  background-position: 14px center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  fill: #98a2b3;
}

.input_user {
  padding-left: 40px;
  background-image: url('../../../assets/icons/user.svg');
  background-position: 14px center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  fill: #98a2b3;
}

.help-circle-block {
  position: absolute;
  margin: 0 8px 0 auto;
  right: 5px;
  top: 40px;
}

.saved-icon{
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  position: absolute;
  right: 14px;
  top: 40px;
  cursor: pointer;
  transform: translateX(-50%);
}

.gender-container{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 20px;
  max-width: 100%;
}

.input_select{
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('../../../assets/icons/chevron-icon.svg');
  background-position: calc(100% - 14px) center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  box-sizing: border-box;
}

.input__item {
  position: absolute;
  display: flex;
  align-items: center;
  top: 36px;
  left: 14px;
  gap: 8px;
  z-index: 19999;
}

.input__item-title {
  margin: 0;
  padding: 0;
  color: var(--Gray-900, #101828);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.input__item-sub-title {
  margin: 0;
  padding: 0;
  color: var(--Gray-500, #667085);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.calendar{
  display: flex;
  position: absolute;
  right: 10px;
  top: 35px;
  width: 40px;
}

.calendar button{
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  width: auto;
}

@media (min-width: 768px) and (max-width: 1080px) {
  .button-setting-confirm {
    position: static;
    width: 100%;
    text-align: right;
  }

  .button-setting-confirm button {
    text-align: right;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .button-setting-confirm {
    position: static;
    width: 100%;
    text-align: right;
  }

  .button-setting-confirm button {
    text-align: right;
  }
}
