.city-cover {
  position: relative;
  display: flex;
  gap: 4px;
  padding: 8px;
  height: 200px;
  justify-content: flex-end;
  align-items: flex-end;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--primary-50, #EEF4FF);
}

.cover-changer {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
}

.cover-changer__content {
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin: auto;

  color: var(--Gray-700, #344054);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.cover-changer__text {
  margin: auto;
}

.cover-changer_autohide {
  opacity: 0;
  transition: all .4s;
}

.cover-changer_autohide:hover {
  opacity: 1;
  background-color: var(--primary-50-50, #EEF4FF77);
}

.pioner {
  display: flex;
  gap: 4px;
  z-index: 12;
}

.cover-changer_show {}

@media screen and (max-width: 1280px) {
  .city-cover {
    height: calc(calc(100svw - 60px) * 0.2869440459110473);
  }
}

@media screen and (max-width: 580px) {
  .city-cover {
    min-height: 160px;
    height: calc(calc(100svw - 60px) * 0.35);
  }
}
