.create-post {
  display: flex;
  width: 100%;
  padding: 20px 32px;
  gap: 16px;
  border: 1px solid var(--gray-200, #EAECF0);
  border-top: none;
}

.create-post_right {
  width: 100%;
}

.text-container {
  position: relative;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.create-post__input {
  width: 100%;
  padding: 8px 0;
  z-index: 10;
  max-width: 572px;
  overflow: hidden;
  overflow-wrap: break-word;
}

.create-post__input div {
  max-width: calc(100svw - 88px);
  overflow: hidden;
  overflow-wrap: break-word;
}

.create-post__input:focus-visible {
  outline: none;
}

.create-post__input_placeholder {
  position: absolute;
  top: 8px;
  width: 100%;
  z-index: -1;
  color: var(--Gray-400, #98A2B3);
}

.create-post_bottom {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 12px;
}

.create-post_buttons {
  display: flex;
  gap: 4px;
}

.input {
  display: none !important;
}

.custom-file-upload {
  width: 38px;
  height: 38px;
  z-index: 10;
  background: transparent;
}

.upload-media-button {
  margin-left: -42px;
}

.form-picture {
  position: relative;
  margin: 0 !important;
  z-index: 10;
}

.uploading-files {
  display: flex;
  gap: 8px;
  padding-top: 4px;
  flex-wrap: wrap;
}

.create-post__send-button-mobile {
  display: none;
}

.links-data {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 12px;
}

@media screen and (max-width: 1280px) {
  .create-post__input {
    max-width: calc(100svw - 180px);
  }
}

@media screen and (max-width: 580px) {
  .create-post {
    display: none;
  }

  .create-post__send-button-mobile {
    display: flex;
  }

  .create-post__input {
    max-width: calc(100svw - 91px);
    max-height: calc(100svh - 194px);
    overflow: auto;
    margin-bottom: 16px;
  }
}

.create-post_mobile {
  display: flex !important;
  gap: 16px;
  min-height: calc(100svh - 160px);
  max-width: calc(100svw - 32px);
}

.create-post_mobile .create-post_bottom {
  margin-top: auto;
}

.create-post_mobile .create-post_right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.create-post_mobile .create-post__send-button {
  display: none;
}

.create-post__dropdown-user-list {
  margin-top: 20px;
}

.create-post__input span {
  padding: 0 2px;
  background: var(--primary-100, #DFEBFF);
  border: 1px solid var(--primary-200, #CADCFC);
  border-radius: 4px;
  color: var(--primary-900, #032C78);
}

.smiles-media-button:focus {
 background: none;
 border: none;
 box-shadow: none;
}