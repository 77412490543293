.placesContainer {
  position: absolute;
  margin-top: 8px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 70%;
}

.mapContainer {
  width: 100%;
  height: calc(100svh - 72px);
  position: relative;
}
