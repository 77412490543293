.drop-menu {
  margin: 8px;
  border-radius: 8px;
  border: 1px solid var(--gray-100, #F2F4F7);
  background: var(--White, #FFF);
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  z-index: 9000;
}

.drop-menu_shadow{
  box-shadow: none;
  border: none;
  margin-left: 0px;
}

.drop-menu_scroll {
  overflow-x: auto;
  max-height: 320px;
}

.item {
  position: relative;
  display: flex;
  padding: 10px 14px;
  gap: 8px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: default;
}

.menu-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  gap: 12px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: default;
}

.menu-settings,
.menu-notification {
  display: flex;
  justify-content: space-between;
  padding:12px 12px 12px 32px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: default;
}

.menu-settings_icon {
  margin-left: auto;
}

.menu-notification_item{
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.item:hover {
  background: var(--gray-50, #F9FAFB);
}

.menu-item:hover {
  background: var(--gray-50, #F9FAFB);
}

.menu-settings:hover{
  background: var(--gray-50, #F9FAFB);
  box-shadow: inset 3px 0 0 0 var(--primary-600-main, #367DFF)
}

.menu-notification:hover{
  background: var(--gray-50, #F9FAFB);
  box-shadow: inset 3px 0 0 0 var(--primary-600-main, #367DFF)
}

.item__title {
  color: var(--gray-900, #101828);
  max-width: 186px;
  text-overflow: ellipsis;
  overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
  line-clamp: 1;
}

.item__title_disabled {
  color: var(--gray-200, #EAECF0);
}

.item__sub-title {
  color: var(--gray-400, #98A2B3);
  font-weight: 400;
}

.item__sub-title_disabled {
  color: var(--gray-200, #EAECF0);
}

.item__set-title{
  color: var(--gray-700, #344054);
}

.item__check {
  position: absolute;
  right: 14px;
}

.item__icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.hr {
  height: 1px;
  width: 100%;
  background-color: var(--gray-100, #F2F4F7);
}

.header {
  font-family: Inter;
  padding: 12px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: var(--gray-700, #344054);
  margin: 0;
}
.user-header {
  padding: 12px 16px;
  display: flex;
  gap: 12px;
}

.user-header button{
  align-items: center;
  margin-left: auto;
}

.user-header__icon img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.user-header__info {
  display: flex;
  flex-direction: column;
}

.user-header__fullname {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: var(--gray-700, #344054);
  margin: 0;
}

.user-header__username {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--gray-400, #98A2B3);
  margin: 0;
}


.drop-menu_scroll::-webkit-scrollbar {
  width: 4px;
}

.drop-menu_scroll::-webkit-scrollbar-track {
  border-radius: 2px;
  margin: 4px;
}

.drop-menu_scroll::-webkit-scrollbar-thumb {
  background: var(--gray-300, #D0D5DD);
  border-radius: 10px;
}

.drop-menu_scroll::-webkit-scrollbar-thumb:hover {
  background: var(--gray-400, #98A2B3);
}

.indicate {
  color: var(--primary-600-main, #367DFF);
  font-weight: 600;
}

.item__button-title {
  color: var(--primary-700, #1E5BCB);
  max-width: 186px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-clamp: 1;
}

.item__button-title_disabled {
  color: var(--gray-200, #EAECF0);
}

.menu-item__content {
  display: flex;
  gap: 8px;
}

.drop-menu_extended{
  display: flex;
  flex-direction: column;
  position: relative;
}

.user-menu__button{
  margin: 0 16px 0 16px;
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0;
}

@media (min-width: 375px) and (max-width: 767px) {
  .menu-settings,
  .menu-notification{
    padding: 12px 12px 12px 16px;
  }
}