.no-comments {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
}

.no-comments__image {
  margin-bottom: 32px;
  width: 236px;
  height: 219px;
  background: url(../../../assets/images/no-comments.svg) no-repeat center;
  background-size: contain;
}

.no-comments__text {
  margin-bottom: 16px;

  color: var(--Gray-700, #344054);
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}


@media screen and (max-width: 580px) {
  .no-comments {
    padding: 24px 0;
  }

  .no-comments__button {
    width: 100%;
  }
}