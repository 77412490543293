.report-content {
  display: flex;
  flex-direction: column;
}

.reactions-summary {
  display: flex;
  gap: 4px;
}

.reactions-modal-follow {
  padding: 8px 8px;
}


.reactions-modal-follow:hover {
  border-radius: 8px;
  background: var(--Gray-50, #F9FAFB);
}