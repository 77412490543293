.container {
  position: relative;
  display: flex;
  padding: 0 20px;
  background: #344054CC;
  overflow: hidden;
}

.line {
  position: absolute;
  width: 6px;
  top: -0px;
  height: calc(100% + 100px);
  transform: rotate(15deg);
  flex-shrink: 0;
  background-color: #0C0F144F;
  z-index: 1;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  color: #fff;
  opacity: 0.6;
  font-family: var(--bs-body-font-family);
  font-weight: 300;
  z-index: 2;
}