.main-page {
  gap: 32px;
}

.container {
  display: flex;

}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(52, 64, 84, 0.80);
  z-index: 3000;
  display: none;
}

.overlay_active {
  display: block;
}

@media screen and (max-width: 1280px) {
  .container {
    gap: 0;
    width: 100%;
  }
}
