.code-block {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.code-block_sm {
  gap: 8px;
}

.phoneTitle {
  padding: 0;
  margin: 0;
  color: var(--gray-700, #344054);
  font-family: Inter;
  text-shadow: 0px 0px 0px #DFEBFF, 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.dash {
  display: flex;
  color: var(--gray-300, #D0D5DD);
  letter-spacing: -1.2px;
  font-size: 60px;
  font-weight: 500;
  line-height: 20px;
  margin: -10px 0 0;
  max-height: 20px;
}

.dash_sm {
  color: var(--gray-300, #D0D5DD);
  font-size: 60px;
  font-weight: 500;
}