.header {
  display: flex;
  padding: 12px 16px 8px;
  justify-content: space-between;
  align-items: center;
}

.title {
  margin: 0;
  color: var(--Gray-700, #344054);
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}

.loader-text {
  padding: 0 12px 12px 12px;
  font-size: 14px;
}