.dropdown-user-list {
  position: absolute;
}

.create-post_bottom {
  position: relative;
}

.dropdown-user-list__loader {
  padding: 16px 8px;
  margin: -12px 0 8px 0;
  min-width: 100%;
  border-radius: 8px;
  border: 1px solid var(--gray-100, #F2F4F7);
  background: var(--White, #FFF);
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  z-index: 9000;
  min-width: 290px;
}