.user-modal-item {
  margin: 20px 0;
  display: flex;
  gap: 12px;
  align-self: stretch;
}

.user-modal-item__avatar {
  width: 48px;
  min-width: 48px;
  max-width: 48px;
}

.user-modal-item__right {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0;
}

.user-modal-item__header {
  display: flex;
  gap: 8px;
}

.user-modal-item__fullname {
  color: var(--Gray-700, #344054);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 0;
  margin: 0;
}

.user-modal-item__time {
  padding: 0;
  margin: 0;
  color: var(--Gray-400, #98A2B3);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
}