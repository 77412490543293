.content {
  width: 100%;
  max-width: 698px;
}

.content-full-width {
  max-width: 100%;
}

@media screen and (max-width: 1280px) {
  .content {
    width: calc(100% - 60px);
    max-width: calc(100svw - 60px);
  }
}

@media screen and (max-width: 580px) {
  .content {
    width: 100%;
    max-width: calc(100svw - 0px);
  }
}