.create-comment {
  display: flex;
  width: 100%;
  padding: 12px;
  align-items: center;
}

.create-comment__avatar {}

.create-comment__input-container {
  position: relative;
  display: flex;
  width: calc(100% - 78px);
  padding: 4px 12px 4px 12px;
  margin-right: 4px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  max-height: 100px;
  overflow-x: hidden;
  overflow-y: auto;
}

.create-comment__input-container::-webkit-scrollbar {
  width: 4px;
}

.create-comment__input-container::-webkit-scrollbar-track {
  border-radius: 2px;
  margin: 4px;
}

.create-comment__input-container::-webkit-scrollbar-thumb {
  background: var(--gray-300, #D0D5DD);
  border-radius: 10px;
}

.create-comment__input-container::-webkit-scrollbar-thumb:hover {
  background: var(--gray-400, #98A2B3);
}

.create-comment__input {
  width: 100%;
  z-index: 5999;
  white-space: pre;
}

.create-comment__input:focus-visible {
  outline: none;
}

.create-comment__input_placeholder {
  position: absolute;
  display: flex;
  width: 100%;
  top: 4px;
  z-index: 3990;
  color: var(--Gray-400, #98A2B3);

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.create-comment__dropdown-user-list {
  width: 100%;
  margin: 0;
  margin-left: -12px;
  bottom: 62px;
  box-shadow: none;
  padding: 0;
  z-index: 9999;
}

.create-comment__dropdown-user-list div {
  box-shadow: none;
  border-radius: 0;
}

.create-comment__input span {
  padding: 0 2px;
  background: var(--primary-100, #DFEBFF);
  border: 1px solid var(--primary-200, #CADCFC);
  border-radius: 4px;
  color: var(--primary-900, #032C78);
}


@media screen and (max-width: 580px) {
  .create-comment {
    padding: 8px 12px;
  }
}