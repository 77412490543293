.comment-list__sub-comments {
  margin-top: 16px;
}

.comment-list__sub-comments p, a {
  max-width: 537px;
}

.comment__answers {
  margin-top: 8px;
}

@media screen and (max-width: 1280px) {
  .comment-list__sub-comments p, a {
    max-width: calc(100svw - 173px);
  }
}

@media screen and (max-width: 580px) {
  .comment-list__sub-comments p, a {
    max-width: calc(100svw - 160px);
  }
}