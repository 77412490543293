.comboboxInput {
  width: 100%;
  padding: 0.5rem;
}

.formError {
  position: absolute;
  text-align: center;
  top: 64px;
  width: calc(100% - 24px);
  color: #ee1a1a;
  font-size: 1.2rem;
  font-weight: 600;
  background: #f8f6f1a8;
  text-shadow: 0px 0px 4px #ffffffc4;
}

.button {
  min-width: 130px;
  margin-left: 8px;
}