.body {
  max-width: var(--content-width);
  min-height: 100svh;
  background-image: url('../../../assets/images/bg-desktop.png');
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  flex-direction: column;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.container {
  display: flex;
  width: 592px;
  padding:  32px 32px 32px 32px;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background: #FFF;
  margin-bottom: 50px;
  box-shadow: 0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A;
}

.container_registration{
  margin-bottom: 50px;
}

.container_confirm-password{
  margin-bottom: 565px;
}

.container_confirm-sms-or-whats{
  margin-bottom: 715px;
}

.container_confirm-phone-recover{
  margin-bottom: 576px;
}

.container_confirm-pass-recover{
  margin-bottom: 470px;
}

.logo {
  display: flex;
  padding: 16px 0 48px 0;
}

.logoImg {
  width: 178px;
  height: 72px;
}


.data {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container_registration-map {
  width: auto;
}

@media (min-width: 768px) and (max-width: 1080px) {
  .body {
    background-image: url('../../../assets/images/bg-tablet.png');
  }

  .container_registration{
    margin-bottom: 136px;
    width: 704px;
  }

  .container_confirm-password{
    margin-bottom: 626px;
    width: 704px;
  }

  .container_confirm-sms-or-whats{
    margin-bottom: 790px;
    width: 704px;
  }

  .container_confirm-phone-recover{
    margin-bottom: 640px;
    width: 704px;
  }

  .container_confirm-pass-recover{
    margin-bottom: 535px;
    width: 704px;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .body {
    background-image: url('../../../assets/images/bg-mobile.png');
  }

  .logoImg {
    width: 164px;
    height: 67px;
  }

  .logo {
    display: flex;
    padding: 16px 0 32px 0;
  }

  .container {
    width: 375px;
    padding:  24px 16px 24px 16px;
    margin-bottom: 70px;
  }

  .container_confirm-password{
    margin-bottom: 435px;
  }

  .container_confirm-sms-or-whats{
    margin-bottom: 558px;
  }

  .container_confirm-phone-recover{
    margin-bottom: 476px;
  }

  .container_confirm-pass-recover{
    margin-bottom: 350px;
  }
}