.container {
  display: flex;
  gap: 8px;
  align-items: flex-end;
  cursor: default;
}

.input-checkbox {
  content: "";
  display: inline-block;
  background: transparent;
  opacity: 0;
  position: fixed;
}

.icon {
  border: 1px solid var(--gray-300, #D0D5DD);
}
.icon:hover {
  border: 1px solid var(--primary-600-main, #367DFF);
  background-color: var(--primary-50, #EEF4FF);
}
.input-checkbox:focus + .icon {
  box-shadow: 0px 0px 0px 4px #DFEBFF;
}

.icon_sm {
  min-width: 16px;
  min-height: 16px;
  max-height: 16px;
  margin-top: 2px;
  border-radius: 4px;
}
.icon_md {
  min-width: 20px;
  min-height: 20px;
  max-height: 20px;
  margin-top: 2px;
  border-radius: 6px;
}

.icon_circle {
  border-radius: 50%;
}

.icon__checked {
  border: 1px solid var(--primary-600-main, #367DFF);
  background-color: var(--primary-50, #EEF4FF);
}

.icon__checkbox_checked-sm,
.icon__checkbox_checked-md {
  background-image: url('../../../assets/checkbox/check.svg');
  background-repeat: no-repeat;
}
.icon__checkbox_checked-sm {
  background-size: 12px;
  background-position: 1px;
}
.icon__checkbox_checked-md {
  background-size: 14px;
  background-position: 2px;
}

.icon__checkbox_indeterminate-sm,
.icon__checkbox_indeterminate-md {
  background-image: url('../../../assets/checkbox/minus.svg');
  background-repeat: no-repeat;
}
.icon__checkbox_indeterminate-sm {
  background-size: 12px;
  background-position: 1px;
}
.icon__checkbox_indeterminate-md {
  background-size: 14px;
  background-position: 2px;
}

.icon__radio_checked-sm,
.icon__radio_checked-md {
  background-repeat: no-repeat;
  background-position: -1px;
}
.icon__radio_checked-sm {
  background-image: url('../../../assets/checkbox/radio-16.svg');
  background-size: 16px;
}
.icon__radio_checked-md {
  background-image: url('../../../assets/checkbox/radio-20.svg');
  background-size: 20px;
}

.icon__circle_checked-sm,
.icon__circle_checked-md {
  background: var(--primary-600-main, #367DFF);
  background-image: url('../../../assets/checkbox/check-white.svg');
  background-repeat: no-repeat;
}
.icon__circle_checked-sm {
  background-size: 10px;
  background-position: 2px;
}
.icon__circle_checked-md {
  background-size: 14px;
  background-position: 2px 3px;
}
.icon__circle_checked-sm:hover,
.icon__circle_checked-md:hover {
  background-color: var(--primary-600-main, #367DFF);
}

/* .right {
  margin-bottom: 6px;
} */

.label {
  color: var(--gray-700, #344054);
  font-family: Inter;
  font-weight: 500;
}

.label_sm {
  font-size: 14px;
  line-height: 20px;
}
.label_md {
  font-size: 16px;
  line-height: 24px;
}

.children {
  color: var(--gray-500, #667085);
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.icon_disabled,
.icon_disabled:hover {
  border: 1px solid var(--gray-200, #EAECF0);
  background-color: var(--gray-100, #F2F4F7);
}

.icon__checkbox_checked_disabled-sm,
.icon__checkbox_checked_disabled-md {
  background-image: url('../../../assets/checkbox/check-gray.svg');
}

.icon__checkbox_indeterminate_disabled-sm,
.icon__checkbox_indeterminate_disabled-md {
  background-image: url('../../../assets/checkbox/minus-gray.svg');
}

.icon__radio_checked_disabled-sm {
  background-image: url('../../../assets/checkbox/radio-16-gray.svg');
}
.icon__radio_checked_disabled-md {
  background-image: url('../../../assets/checkbox/radio-20-gray.svg');
}

.icon__circle_checked_disabled-sm,
.icon__circle_checked_disabled-md,
.icon__circle_checked_disabled-sm:hover,
.icon__circle_checked_disabled-md:hover {
  background-color: var(--gray-200, #EAECF0);
}

.notification{
  display: flex;
  justify-content: space-between;
}

.label_disabled{
  color: var(--gray-400, #98A2B3);
}