.uploading-file {
  position: relative;
  width: 108px;
  height: 108px;
}

.uploading-file__item {
  position: relative;
  top: 0;
  position: absolute;
  width: 108px !important;
  height: 108px !important;
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
}

.uploading-file__loader {
  position: relative;
  display: flex;
  z-index: 10;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: all .3s;
  opacity: 1;
}

.uploading-file__loader_finished {
  opacity: 0;
}

.uploading-file__close {
  position: absolute;
  right: 4px;
  top: 4px;

  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: var(--Gray-100, #F2F4F7);
  z-index: 10;
  cursor: pointer;
}