.reaction-feedback {
  display: flex;
  position: relative;
  justify-content: space-between;
  min-height: 28px;
}

.reaction-feedback-inzoom {
  padding: 0 16px;
}

.text-feedback-container {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.reactions-summary {
  align-items: center;
  display: flex;
  height: 100%;
}

.reaction-count {
  font-size: 14px;
  font-weight: 500;
  color: var(--Gray-700, #344054);
  margin-left: 8px;
}