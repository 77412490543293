.dropdown__field {
  display: flex;
  max-width: 320px;
  height: 44px;
  max-height: 44px;
  padding: 0 14px;
  margin: 8px;
  margin-bottom: 0;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  border-radius: 8px;
  border: 1px solid var(--gray-300, #D0D5DD);
  background: var(--White, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  cursor: default;

  transition: all .1s;
}

.dropdown__field_empty {
  border: 1px solid var(--gray-200, #EAECF0);
}

.dropdown__field_active {
  border: 1px solid var(--primary-300, #BBD3FF);
  box-shadow: 0px 0px 0px 4px #F4EBFF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.dropdown__placeholder {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}

.dropdown__placeholder-dot {
  margin: auto 0;
}

.dropdown__placeholder-icon img {
  width: 24px;
  height: 24px;
}

.dropdown__placeholder-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--gray-400, #98A2B3);
}


.dropdown__item-data {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}

.dropdown__item-dot {
  margin: auto 0;
}

.dropdown__item-icon img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.dropdown__item-title {
  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.dropdown__item-sub-title {
  color: var(--gray-500, #667085);
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}