:root {
  --display-font-size-2xl: 72px;
  --display-font-size-xl: 32px;
  --display-font-size-lg: 48px;
  --display-font-size-md: 36px;
  --display-font-size-sm: 30px;
  --display-font-size-xs: 24px;

  --text-font-size-xl: 20px;
  --text-font-size-lg: 18px;
  --text-font-size-md: 16px;
  --text-font-size-sm: 14px;
  --text-font-size-xs: 12px;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
}
