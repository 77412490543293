.share-dialog {
  display: flex;
  flex-direction: column;
}

.share-dialog__input-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  width: 100%;
  align-items: flex-end;
  margin-bottom: 20px;
}

.share-dialog__input {
  width: 100%;
  margin-bottom: 0;
}

.share-dialog__copy-btn {
  margin-bottom: 2px;
}

.share-dialog__buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 32px;
}

.share-dialog__buttons-line {
  display: flex;
  justify-content: stretch;
  gap: 20px;
}

@media screen and (max-width: 1280px) {
  .share-dialog__buttons {
    display: none;
  }
}