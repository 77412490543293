.main-page {
  gap: 32px;
}

.tabs {
  border: 1px solid var(--gray-200, #EAECF0);
  border-top: none;
  border-bottom: none;
  margin: 0;
}

.container {
  display: flex;
  gap: 32px;
}


@media screen and (max-width: 1280px) {
  .container {
    gap: 0;
    width: 100%;
  }
}
