.media-one {
  display: flex;
  gap: 16px;
}

.media-one_first {
  height: 296px !important;
  width: 100% !important;
}

/* 2 */
.media-two {
  display: flex;
  gap: 16px;
  width: 100% !important;
  height: 296px !important;
}

.media-two_item {
  width: 100% !important;
  height: 296px !important;
}

/* 3 */

.media-three {
  display: grid;
  width: 100% !important;
  height: 296px !important;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    "left top"
    "left bottom";
}

.media-three_left {
  width: 100% !important;
  height: 296px !important;
  grid-area: left;
}

.media-three_top {
  width: 100% !important;
  height: 140px !important;
  grid-area: top;
}

.media-three_bottom {
  width: 100% !important;
  height: 140px !important;
  grid-area: bottom;
}

/* 4 */

.media-four {
  display: grid;
  width: 100%;
  height: 452px;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'first second'
    'first second'
    'third fourth';
}

.media-four_first {
  width: 100% !important;
  height: 296px !important;
  grid-area: first;
}

.media-four_second {
  width: 100% !important;
  height: 296px !important;
  grid-area: second;
}

.media-four_third {
  width: 100% !important;
  height: 140px !important;
  grid-area: third;
}

.media-four_fourth {
  width: 100% !important;
  height: 140px !important;
  grid-area: fourth;
}

/* 5+ */

.media-more {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 452px;
  gap: 16px;
}

.media-more__top {
  display: flex;
  flex-wrap: inherit;
  gap: 16px;
}

.media-more__bottom {
  display: flex;
  gap: 16px;
  max-width: 100%;
}

.media-more_first {
  width: 100% !important;
  height: 296px !important;
}

.media-more_second {
  width: 100% !important;
  height: 296px !important;
}

.media-more_third {
  width: 100% !important;
  height: 140px !important;
}

.media-more_fourth {
  width: 100% !important;
  height: 140px !important;
}

.media-more__more {
  min-width: 155px;
  min-height: 140px;
}
