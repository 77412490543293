.user-menu {
  position: fixed;
  bottom: 60px;
  left: calc((100svw - 1280px) / 2);
  width: 208px;
  z-index: 3999;
}

.user-menu__button{
  position: absolute;
  bottom: -300px;
  left: 0;
  width: 100%;
}

@media screen and (max-width: 1280px) {
  .user-menu {
    bottom: auto;
    left: auto;
    top: 8px;
    right: 8px;
    width: 390px;
    height: calc((100svh - 30px));
  }
}

@media screen and (max-width: 580px) {
  .user-menu {
    right: 8px;
    width: 296px;
  }
  .user-menu_hide {
    display: none;
  }
}