.post-with-commets {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  border-top: none;
}

.post-with-commets__header {
  display: flex;
  gap: 12px;
  padding: 0 16px;
}

.post-with-commets__top-right {
  display: flex;
  flex-direction: column;
}

.post-with-commets__name {
  color: var(--Gray-700, #344054);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.post-with-commets__second-text {
  display: flex;
  align-items: center;
  color: var(--Gray-400, #98A2B3);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.post-with-commets__second-text p {
  margin: 0;
}

.post-with-commets__dot {
  width: 4px;
  height: 4px;
  background: var(--Gray-200, #EAECF0);
  margin: 0 6px;
  border-radius: 2px;
}

.post-with-commets__text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  align-self: stretch;
  overflow: hidden;
  color: var(--Gray-700, #344054);
  text-overflow: ellipsis;

  padding: 0 16px;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.post-with-commets__text_full {
  -webkit-line-clamp: 9999 !important;
}

.post-with-commets__more-button {
  margin-top: -10px;
  padding: 0 16px;
}

.post-with-commets__bottom {
  display: flex;
  width: 100%;
  gap: 4px;
  padding: 0 16px;
}

.post__reactions-conteiner {
  display: flex;
  flex-grow: 1;
}

.post-with-commets__reactions-button {
  width: 100%;
}

.create-comment {
  border: 1px solid var(--Gray-200, #EAECF0);
  background: var(--White, #FFF);
}

.post-with-commets__comment-list {
  padding: 0 16px;
}

.post-with-commets__avatar-mobile {
  display: none;
}

.post__with-commets-button {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
}


@media screen and (max-width: 1280px) {
  .post-with-commets__reactions-button {
    width: 134px;
  }

  .create-comment {
    margin-top: auto;
  }
}

@media screen and (max-width: 580px) {
  .post-with-commets {
    gap: 4px;
  }

  .post-with-commets__header {
    padding: 0 8px
  }

  .post-with-commets__reactions-button {
    width: 100%;
  }

  .post-with-commets__avatar {
    display: none;
  }

  .post-with-commets__avatar-mobile {
    display: flex;
  }

  .post-with-commets__top-right {
    flex-direction: row;
  }

  .post-with-commets__got {
    margin: 0 4px;
  }

  .post-with-commets__name {
    font-size: 14px;
    margin-left: 8px;
  }

  .post-with-commets__time {
    font-size: 14px;
  }

  .post-with-commets__city {
    display: none;
  }

  .post-with-commets__more-button {
    margin-top: -4px;
    padding: 0 8px;
  }

  .post-with-commets__text,
  .post-with-commets__bottom,
  .reactions {
    padding: 0 8px;
  }
}
