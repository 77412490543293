.comment {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
  transition: all .5s;
}

.comment__right {
  display: flex;
  align-self: stretch;
  min-width: calc(360px - 48px);
  width: 100%;
  flex-direction: column;
}

.comment__header {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  /* align-items: baseline; */
}

.comment__fullname {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;

  color: var(--Gray-700, #344054);
  
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  margin: 0;
  padding: 0;
}

.comment__time {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  color: var(--Gray-400, #98A2B3);
  text-overflow: ellipsis;
  
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.comment__text {
  color: var(--Gray-700, #344054);
  text-overflow: ellipsis;
  
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  max-width: 584px;
}

.comment__footer {
  display: flex;
  gap: 8px;
  margin-top: 4px;
}

.comment__answers {
  display: flex;
  margin-right: auto;
  gap: 8px;
  margin-top: 4px;
}

.comment__item-menu {
  margin-left: auto;
  margin-top: -8px;
}

.comment__hide {
  opacity: 0;
  max-width: 0;
  min-height: 0;
  height: 0;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;
}

@media screen and (max-width: 1280px) {
  .comment__text {
    max-width: calc(100svw - 173px);
  }
}

@media screen and (max-width: 580px) {
  .comment__text {
    max-width: calc(100svw - 113px);
  }
}