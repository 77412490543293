.post {
  display: flex;
  flex-direction: column;
  padding: 20px 32px;
  gap: 16px;
  border: 1px solid var(--gray-200, #EAECF0);
  border-top: none;
  transition: all .5s;
}

.post__header {
  display: flex;
  gap: 12px;
}

.post__top-right {
  display: flex;
  flex-direction: column;
}

.post__name-link {
  cursor: pointer;
}

.post__name {
  display: flex;
  gap: 8px;
  color: var(--Gray-700, #344054);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  align-items: center;
  position: relative;
}

.post__second-text {
  color: var(--Gray-400, #98A2B3);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.post__tooltip {
  position: relative;
  display: flex;
}

.post__tooltip-text {
  cursor: pointer;
  align-items: center;
  color: var(--Gray-400, #98A2B3);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.post__content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  align-self: stretch;
  overflow: hidden;

  color: var(--Gray-700, #344054);
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  max-width: 631px;
}

.post__content_full {
  -webkit-line-clamp: 9999;
}

.post__more-button {
  margin-top: -10px;
  margin-right: auto;
}

.post__bottom {
  display: flex;
  gap: 8px;
}

.post__bottom-button {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
}

.post__reactions-conteiner {
  display: flex;
  flex-grow: 1;
}

.carousel {
  display: none;
}

.post__more-menu {
  margin-left: auto;
}

.display_none {
  transition: all .5s;
  height: 0;
  min-height: 0;
  max-height: 0 !important;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
  border: none;
}

.links-data {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.roditel {
  position: relative;
}

.post-edited-badge {
  color: var(--Gray-400, #98A2B3);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
  align-self: end;
}

@media screen and (max-width: 1280px) {
  .post {
    padding: 12px 16px;
    gap: 12px;
  }

  .carousel {
    display: block;
    height: calc(100svh - 246px);
    max-height: calc(100svw - 94px);
  }

  .media-content {
    display: none !important;
  }

  .post__content {
    max-width: calc(100svw - 92px);
  }
}

@media screen and (max-width: 580px) {
  .carousel {
    max-height: calc(100svw - 34px);
  }

  .post__content {
    max-width: calc(100svw - 32px);
  }

  .post__tooltip-text {
    font-size: 14px;
    line-height: 20px;
  }
}