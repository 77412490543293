.login-right {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 312px;
  margin-top: 8px;
}

.title {
  color: var(--Gray-700, #344054);
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}

.button {
  padding: 8px;
}

.footer {
  display: flex;
  gap: 4px;
  align-items: baseline;
  color: var(--Gray-500, #667085);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.footer p {
  margin: 0;
}