.feedback {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-self: center;
}

.feedback-text {
  color: var(--Gray-400, #98A2B3);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: right;
}


@media screen and (max-width: 580px) {
  .feedback {
    gap: 8px;
  }
}