.for-report-item__loader {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  min-height: 104px;
  max-height: 104px;
  border-radius: 12px;
  border: 1px solid var(--Gray-200, #EAECF0);
}

.for-report-item {
  margin: 20px 0;
  display: flex;
  min-height: 104px;
  padding: 16px;
  gap: 12px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Gray-200, #EAECF0);
}

.for-report-item-user {
  margin: 20px 0;
  display: flex;
  gap: 12px;
  align-self: stretch;
}

.for-report-item__avatar {
  width: 48px;
  min-width: 48px;
  max-width: 48px;
}

.for-report-item__right {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0;
}

.for-report-item__header {
  display: flex;
  gap: 8px;
}

.for-report-item__fullname {
  color: var(--Gray-700, #344054);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 0;
  margin: 0;
}

.for-report-item__time {
  padding: 0;
  margin: 0;
  color: var(--Gray-400, #98A2B3);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
  }

.for-report-item__text {
  padding: 0;
  margin: 0;
  max-height: 72px;
  overflow: hidden;
  color: var(--Gray-700, #344054);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
