.pictures {
  border-radius: 8px;
  height: 100%;
}

.media-file {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0;
}

.carousel {
  position: relative;
}

.dot-indicator {
  position: absolute;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  bottom: 16px;
  width: 100%;
  z-index: 1;
}