.dot-indicator {
  display: flex;
  width: min-content;
}
.dot-indicator div {
  cursor: pointer;
}

/* size & style */
.dot-indicator_style_dot-md {
  gap: 12px;
  padding: 8px;
  border-radius: 12px;
}
.dot-indicator_style_dot-md div {
  width: 8px;
  height: 8px;
  border-radius: 4px;
}

.dot-indicator_style_dot-lg {
  gap: 16px;
  padding: 12px;
  border-radius: 17px;
}
.dot-indicator_style_dot-lg div {
  width: 10px;
  height: 10px;
  border-radius: 6px;
}

.dot-indicator_style_line-md {
  gap: 8px;
  padding: 8px;
  border-radius: 11px;
  width: 160px;
}
.dot-indicator_style_line-md div {
  width: 100%;
  height: 6px;
  border-radius: 3px;
}

.dot-indicator_style_line-lg {
  gap: 12px;
  padding: 12px;
  border-radius: 16px;
  width: 176px;
}
.dot-indicator_style_line-lg div {
  width: 100%;
  height: 8px;
  border-radius: 4px;
}

.dot-indicator_clear {
  padding: 0;
}

.dot-indicator_style_line-lg_clear {
  width: 152px;
}

.dot-indicator_style_line-md_clear {
  width: 144px;
}

/* type */

.dot-indicator_primary-light-bg {
  background: var(--primary-600-5, #367DFF80), .5;
}
.dot-indicator_primary-dark-bg {
  background: var(--Primary-50-5, #EEF4FF80), .5;
}
.dot-indicator_gray-light-bg {
  background: var(--White-5, #FFFFFF80);
}
.dot-indicator_gray-dark-bg {
  background: var(--Gray-700-5, #34405480);
}

.dot-indicator_primary-light-bg div {
  background: var(--Primary-500, #5993FE);
}
.dot-indicator_primary-dark-bg div {
  background: var(--Primary-100, #DFEBFF);
}
.dot-indicator_gray-light-bg div {
  background: var(--Gray-300, #D0D5DD);
}
.dot-indicator_gray-dark-bg div {
  background: var(--Gray-500, #667085);
}

.dot-indicator_primary-light-bg-clear div {
  background: var(--Primary-100, #DFEBFF);
}
.dot-indicator_primary-dark-bg-clear div {
  background: var(--Primary-500, #5993FE);
}
.dot-indicator_gray-light-bg-clear div {
  background: var(--Gray-300, #D0D5DD);
}
.dot-indicator_gray-dark-bg-clear div {
  background: var(--Gray-500, #667085);
}

/* active */

.dot-indicator__item_active_primary-light-bg-clear {
  background: var(--primary-600-main, #367DFF) !important;
}

.dot-indicator__item_active_primary-dark-bg-clear {
  background: var(--White, #FFF) !important;
}

.dot-indicator__item_active_gray-light-bg-clear {
  background: var(--Gray-700, #344054) !important;
}

.dot-indicator__item_active_gray-dark-bg-clear {
  background: var(--White, #FFF) !important;
}

.dot-indicator__item_active_primary-light-bg {
  background: var(--White, #FFF) !important;
}

.dot-indicator__item_active_primary-dark-bg {
  background: var(--primary-600-main, #367DFF) !important;
}

.dot-indicator__item_active_gray-light-bg {
  background: var(--Gray-700, #344054) !important;
}

.dot-indicator__item_active_gray-dark-bg {
  background: var(--White, #FFF) !important;
}