.bottom-menu {
  position: fixed;
  min-height: 60px;
  bottom: 0;
  justify-content: space-between;
  top: calc(-webkit-fill-available - 60px);
  left: 0;
  width: 100svw;
  padding: 4px 0;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--Gray-200, #EAECF0);
  border-bottom: none;
  background: var(--White, #FFF);
}

.nav-button {
  display: block;
  margin: 8px;
  min-width: calc(((100svw - 80px) / 5));
  min-height: calc(((100svw - 80px) / 5));
}

.button {
  flex-direction: column;
  align-items: center;
  width: 100svw;
  display: none;
}

.add-post {
  position: fixed;
  width: auto;
  bottom: calc(20svw + 14px);
  z-index: 2999;
}


@media screen and (max-width: 580px) {
  .button {
    display: flex;
  }

  .bottom-menu {
    display: flex;
    z-index: 99;
  }
}
