.item-menu {
  display: flex;
  position: relative;
}

.item-menu__container {
  right: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.item-menu__button {}

.item-menu__menu {
  margin-right: -4px;
}

.item-menu__container_right {
  flex-direction: row-reverse;
  align-items: flex-start;
}

.item-menu__menu_right {
  position: relative;
  width: max-content;
  right: 12px;
  top: -12px;
}
