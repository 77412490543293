.report-content {
  display: flex;
  flex-direction: column;
}

.report-content__selector {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
}

.report-content__title {
  margin: 0;
  padding: 0;
  color: var(--Gray-700, #344054);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.report-content__input {
  margin-bottom: -12px !important;
}

.report-content__button {
  margin-top: 32px;
}
