.right-menu-item {
  border-radius: 12px;
  background: var(--Gray-50, #F9FAFB);
}

.right-menu-item > div {
  border-bottom: solid 1px var(--gray-200, #EAECF0);
}

.right-menu-item > div:nth-last-child(1) {
  border-bottom: none;
}

.right-menu-item > div:nth-child(1) {
  border-bottom: none;
}

.header {
  display: flex;
  padding: 12px 16px 8px;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: var(--Gray-700, #344054);

  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}