.digit-input {
  width: 64px;
  height: 64px;
  padding: 0 0 6px 0;
  font-size: var(--display-font-size-lg);
  color: var(--primary-600-main, #367DFF);
  font-weight: 500;
  border: #367DFF;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #D0D5DD);
  background: var(--White, #FFF);
  text-align: center;
}

.digit-input::placeholder {
  color: var(--gray-300, #D0D5DD);
}

.digit-input:focus {
  outline: 4px solid var(--primary-100, #DFEBFF);
  border: 1px solid var(--primary-300, #ADCAFF);
}

.digit-input_md {
  width: 80px;
  height: 80px;
}

.digit-input_lg {
  width: 96px;
  height: 96px;
  font-size: 60px;
}

.entered {
  box-sizing: initial;
  border-radius: 8px;
}

.entered .digit-input {
  border: 1px solid var(--primary-300, #ADCAFF);
}
