.city-selector {
  position: relative;
}

.dropdown__menu_item {
  width: calc(100% + 48px);
}

.dropdown__menu {
  position: absolute;
  right: 0;
  width: max-content;
  min-height: 10px !important;
  transition: all .1s;
  height: min-content;
  margin: 4px 0;
  padding-right: 48px;
  overflow-x: hidden;
}

.dropdown__menu_hide {
  height: 0;
}

.button {
  justify-content: flex-end;
}