.message-notifications {
  position: absolute;
  top: 0;
  left: 0;
  width: 100svw;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.message-notifications__list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  z-index: 9990;
}

.message-notifications__message {
  display: flex;
  gap: 12px;
  min-width: 384px;
  max-width: 384px;
  padding: 16px 4px 16px 6px;
  border-bottom: 1px solid var(--Gray-200, #EAECF0);
  background: var(--Indigo-25, #F5F8FF);
  align-items: center;
}

.message-notifications__dot {
  margin-right: -3px;
  margin-left: 1px;
  min-width: 6px;
  min-height: 6px;
  border-radius: 50%;
  background: var(--Primary-500, #5993FE);
}

.message-notifications__content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.message-notifications__fullname {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--Gray-700, #344054);
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.message-notifications__text {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  overflow: hidden;
  color: var(--Gray-700, #344054);
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.message-notifications__right-menu {
  position: relative;
  display: flex;
  top: 0;
}

.message-notifications__more-menu {
  top: -24px;
}
