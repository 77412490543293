.avatar-container {
  position: relative;
  border-radius: 50%;
  background-color: var(--gray-50, #F9FAFB);
  border: 1.5px solid var(--White, #FFF);
  box-sizing: content-box;
}

.company-warning {
  background-color: var(--White, #FFF);
}

.avatar-container-xxs {
  width: 16px;
  max-width: 16px;
  min-width: 16px;
  height: 16px;
  max-height: 16px;
  min-height: 16px;
  background-image: url('../../../assets/avatar/user_16.svg');
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center;
}

.avatar-container-xxs.company-warning {
  background-image: url('../../../assets/avatar/warning_16.svg');
}

.avatar-container-xs {
  width: 24px;
  max-width: 24px;
  min-width: 24px;
  height: 24px;
  max-height: 24px;
  min-height: 24px;
  background-image: url('../../../assets/avatar/user_16.svg');
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center;
}

.avatar-container-xs.company-warning {
  background-image: url('../../../assets/avatar/warning_16.svg');
}

.avatar-container-sm {
  width: 32px;
  max-width: 32px;
  min-width: 32px;
  height: 32px;
  max-height: 32px;
  min-height: 32px;
  background-image: url('../../../assets/avatar/user_20.svg');
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
}

.avatar-container-sm.company-warning {
  background-image: url('../../../assets/avatar/warning_20.svg');
}

.avatar-container-md {
  width: 40px;
  max-width: 40px;
  min-width: 40px;
  height: 40px;
  max-height: 40px;
  min-height: 40px;
  background-image: url('../../../assets/avatar/user_24.svg');
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center;
}

.avatar-container-md.company-warning {
  background-image: url('../../../assets/avatar/warning_24.svg');
}

.avatar-container-lg {
  width: 48px;
  max-width: 48px;
  min-width: 48px;
  height: 48px;
  max-height: 48px;
  min-height: 48px;
  background-image: url('../../../assets/avatar/user_28.svg');
  background-repeat: no-repeat;
  background-size: 28px;
  background-position: center;
}

.avatar-container-lg.company-warning {
  background-image: url('../../../assets/avatar/warning_28.svg');
}

.avatar-container-xl {
  width: 56px;
  max-width: 56px;
  min-width: 56px;
  height: 56px;
  max-height: 56px;
  min-height: 56px;
  background-image: url('../../../assets/avatar/user_32.svg');
  background-repeat: no-repeat;
  background-size: 32px;
  background-position: center;
}

.avatar-container-xl.company-warning {
  background-image: url('../../../assets/avatar/warning_32.svg');
}


.avatar-container-2xl {
  width: 64px;
  max-width: 64px;
  min-width: 64px;
  height: 64px;
  max-height: 64px;
  min-height: 64px;
  background-image: url('../../../assets/avatar/user_32.svg');
  background-repeat: no-repeat;
  background-size: 32px;
  background-position: center;
}

.avatar-container-2xl.company-warning {
  background-image: url('../../../assets/avatar/warning_32.svg');
}

.avatar-container-3xl {
  width: 88px;
  min-width: 88px;
  max-width: 88px;
  height: 88px;
  min-height: 88px;
  max-height: 88px;
  padding: 4px;
  background-color: #ffffff;
  box-shadow: 0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-picture {
  vertical-align: baseline;
  border-radius: 50%;
}

.avatar-picture-xxs {
  width: 16px;
  max-width: 16px;
  height: 16px;
  max-height: 16px;
}

.avatar-picture-xs {
  width: 24px;
  max-width: 24px;
  height: 24px;
  max-height: 24px;
}

.avatar-picture-sm {
  width: 32px;
  max-width: 32px;
  height: 32px;
  max-height: 32px;
}

.avatar-picture-md {
  width: 40px;
  max-width: 40px;
  height: 40px;
  max-height: 40px;
}

.avatar-picture-lg {
  width: 48px;
  max-width: 48px;
  height: 48px;
  max-height: 48px;
}

.avatar-picture-xl {
  width: 56px;
  max-width: 56px;
  height: 56px;
  max-height: 56px;
}

.avatar-picture-2xl {
  width: 64px;
  max-width: 64px;
  height: 64px;
  max-height: 64px;
}

.avatar-picture-3xl {
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: center;
  background-image: url('../../../assets/avatar/user_32.svg');
  background-color: var(--primary-50, #EEF4FF);
  background-repeat: no-repeat;
  background-size: 38px;
  background-position: center;
}

.avatar-container .live {
  display: none;
}

.avatar-container_online {
  position: relative;
}

.avatar-container_online .live {
  display: flex;
}

.online {
  /* display: none; */
  position: absolute;
  bottom: 0;
  right: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 1.5px solid var(--White, #FFFFFF);
  background: var(--Success-500, #12B76A);
  z-index: 9;
}

.company {
  /* display: none; */
  position: absolute;
  bottom: 0;
  right: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 1.5px solid var(--White, #FFFFFF);
  background: var(--warning-600, #DC6803);
  z-index: 9;
}


.online_show {
  display: block;
}

.online-xxs {
  width: 6px;
  height: 6px;
}

.online-xs {
  width: 6px;
  height: 6px;
}

.online-sm {
  width: 8px;
  height: 8px;
}

.online-md {
  width: 10px;
  height: 10px;
}

.online-lg {
  width: 12px;
  height: 12px;
}

.online-xl {
  width: 14px;
  height: 14px;
}

.online-2xl {
  width: 16px;
  height: 16px;
}

.online-3xl {
  border: 3px solid var(--White, #FFFFFF);
  width: 22px;
  height: 22px;
}

.online-4xl {
  border: 4px solid var(--White, #FFFFFF);
  width: 32px;
  height: 32px;
}

.live {
  position: absolute;
  border-radius: 50%;
  border: 2px solid var(--Error-600, #D92D20);
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  top: -4px;
  left: -4px;
  z-index: 9;
}

.avatar_number {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--Gray-50, #F9FAFB);
}

.number {
  margin: auto;
  padding: 0;
  color: var(--primary-600-main, #367DFF);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.avatar_pointer {
  cursor: pointer;
}

.reaction {
  position: absolute;
  bottom: -4px;
  right: 0;

  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;

  border-radius: 16px;
  background: var(--Gray-50, #F9FAFB);
}

.icon {
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  background-position: center;
  background-size: 16px;
  background-repeat: no-repeat;
}