.badge {
  display: flex;
  width: fit-content;
  gap: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: 500;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
}

.badge-primary {
  background-color: var(--primary-50, #EEF4FF);
  color: var(--primary-700, #1E5BCB);
}

.badge-error {
  background-color: var(--error-50, #FEF3F2);
  color: var(--error-700, #B42318);
}

.badge-warning {
  background-color: var(--warning-50, #FFFAEB);
  color: var(--warning-700, #B54708);
}

.badge-success {
  background-color: var(--success-50, #ECFDF3);
  color: var(--success-700, #027A48);
}

.badge-indigo {
  background-color: var(--indigo-50, #EEF4FF);
  color: var(--indigo-700, #3538CD);
}

.badge-purple {
  background-color: var(--purple-50, #F4F3FF);
  color: var(--purple-700, #5925DC);
}

.badge-pink {
  background-color: var(--pink-50, #FDF2FA);
  color: var(--pink-700, #C11574);
}

.badge-rose {
  background-color: var(--rose-50, #FFF1F3);
  color: var(--rose-700, #C01048);
}

.badge-orange {
  background-color: var(--orange-50, #FFF6ED);
  color: var(--orange-700, #C4320A);
}

.badge-blue-gray {
  background-color: var(--gray-50, #F9FAFB);
  color: var(--primary-600, #367DFF);
}

.badge-gray {
  background-color: var(--gray-100, #F2F4F7);
  color: var(--gray-700, #344054);
}


.badge-sm {
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
  min-height: 22px;
  min-width: 22px;
  max-height: 22px;
}

.badge-md {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  line-height: 20px;
  min-height: 24px;
  min-width: 24px;
  max-height: 24px;
}

.badge-lg {
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  line-height: 20px;
  min-height: 28px;
  min-width: 28px;
  max-height: 28px;
}

.badge_only-sm {
  padding: 4px !important;
}

.badge_only-md {
  padding: 6px !important;
}

.badge_only-lg {
  padding: 8px !important;
}

.badge_pointer {
  cursor: pointer;
}