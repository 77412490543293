.dropdown {
  position: relative;
}

.dropdown__menu {
  position: absolute;
  width: 100%;
  transition: all .1s;
  height: 320px;
}

.dropdown__menu_hide {
  height: 0;
}