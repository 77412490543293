:root {
  --white: #FFFFFF;
  --white-5: #FFFFFF80;
  --black: #000000;

  --gray-25: #FCFCFD;
  --gray-50: #F9FAFB;
  --gray-100: #F2F4F7;
  --gray-200: #EAECF0;
  --gray-300: #D0D5DD;
  --gray-400: #98A2B3;
  --gray-500: #667085;
  --gray-600: #475467;
  --gray-700: #344054;
  --gray-700-5: #34405480;
  --gray-800: #1D2939;
  --gray-900: #101828;

  --primary-25: #F8FAFF;
  --primary-50: #EEF4FF;
  --primary-50-5: #EEF4FF80;
  --primary-50-50: #EEF4FFBB;
  --primary-100: #DFEBFF;
  --primary-200: #CADCFC;
  --primary-300: #ADCAFF;
  --primary-400: #87B1FD;
  --primary-500: #5993FE;
  --primary-600: #367DFF;
  --primary-600-5: #367DFF80;
  --primary-700: #1E5BCB;
  --primary-800: #0D3C92;
  --primary-900: #032C78;
  --primary-main: var(--primary-600, #367DFF);

  --error-25: #FFFBFA;
  --error-50: #FEF3F2;
  --error-100: #FEE4E2;
  --error-200: #FECDCA;
  --error-300: #FDA29B;
  --error-400: #F97066;
  --error-500: #F04438;
  --error-600: #D92D20;
  --error-700: #B42318;
  --error-800: #912018;
  --error-900: #7A271A;

  --warning-25: #FFFCF5;
  --warning-50: #FFFAEB;
  --warning-100: #FEF0C7;
  --warning-200: #FEDF89;
  --warning-300: #FEC84B;
  --warning-400: #FDB022;
  --warning-500: #F79009;
  --warning-600: #DC6803;
  --warning-700: #B54708;
  --warning-800: #93370D;
  --warning-900: #7A2E0E;

  --success-25: #F6FEF9;
  --success-50: #ECFDF3;
  --success-100: #D1FADF;
  --success-200: #A6F4C5;
  --success-300: #6CE9A6;
  --success-400: #32D583;
  --success-500: #12B76A;
  --success-600: #039855;
  --success-700: #027A48;
  --success-800: #05603A;
  --success-900: #054F31;

  --indigo-50: #EEF4FF;
  --indigo-500: #6172F3;
  --indigo-700: #3538CD;

  --purple-50: #F4F3FF;
  --purple-500: #7A5AF8;
  --purple-700: #5925DC;

  --pink-50: #FDF2FA;
  --pink-500: #EE46BC;
  --pink-700: #C11574;

  --rose-50: #FFF1F3;
  --rose-500: #F63D68;
  --rose-700: #C01048;

  --orange-25: #FFFAF5;
  --orange-50: #FFF6ED;
  --orange-500: #FB6514;
  --orange-700: #C4320A;
}