.comboboxInput {
  width: 100%;
  padding: 0.5rem;
}

.autocomplete-container {
  position: absolute;
  display: flex;
  gap: 20px;
  width: 100%;
}

.geo-button {
  align-self: center;
}

.places-autocomplete {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .autocomplete-container {
    gap: 8px;
    max-width: calc(100% - 24px);
  }
}