.avatar-add-button {
  margin: auto;
}

.custom-file-upload-xs {
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
}

.custom-file-upload-sm {
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
}

.custom-file-upload-md {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
}

.custom-file-upload {
  display: flex;
  box-sizing: border-box;
  z-index: 10;
  border-radius: 50%;
  border: 1px dashed var(--Gray-300, #D0D5DD);
  background-color: var(--White, #FFF);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
  background-image: url('/src/assets/picture-icon/plus.svg');
  cursor: pointer;
}

.custom-file-upload:hover {
  background-color: var(--Gray-50, #F9FAFB);
  background-image: url('/src/assets/picture-icon/plus-hover.svg');
}

.upload-media-button {
  margin-left: -42px;
}

.form-picture {
  position: relative;
  margin: 0 !important;
  z-index: 10;
}

.uploading-files {
  display: flex;
  gap: 8px;
  padding-top: 4px;
  flex-wrap: wrap;
}

.input {
  display: none;
}