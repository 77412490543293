.reaction-feedback {
  display: flex;
  position: relative;
  justify-content: space-between;
}

.reaction-feedback-inzoom {
  display: flex;
}

.reactions {
  display: flex;
  gap: 4px;
}

.reactions-post {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: #ffff;
  z-index: 100;
  left: 0;
  border-radius: 8px;
  padding: 2px;
  box-shadow: 0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A;
  position: absolute;
  bottom: 55px;
  transform: translateX(0);
}

.reactions-post-inzoom {
  bottom: 60px;
}

.reactions-summary {
  align-items: center;
  display: flex;
  height: 100%;
}

.reaction-count {
  font-size: 14px;
  font-weight: 500;
  color: var(--Gray-700, #344054);
  margin-left: 8px;
}